<template>
  <el-container>
    <el-header class="header">
      <div class="left" style="width: 200px">
        <div
          :class="
            isCollapse ? 'iconfont wanlvzuofanye is-close' : 'iconfont wanlvzuofanye'
          "
          @click="toggleCollapse"
        ></div>
        <div class="logo" style="width: 150px" @click="onIndex"></div>
      </div>
      <ul style="flex: 1">
        <li
          v-for="(item, index) in $store.state.columnlist"
          :key="index"
          :class="[item.columnIcon, topActive === index ? 'active' : '']"
          class="iconfont"
          @click="onset(index)"
        >
          {{ item.columnName }}
        </li>
      </ul>
      <div class="userInfo">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-avatar
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <div class="message">
              <i></i>
              <span>用户名</span>
              <i class="el-icon-arrow-down"></i>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span @click="onUpdateUserPassword">修改密码</span>
            </el-dropdown-item>
            <el-dropdown-item><span @click="loginOut">退出登录</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="main-box">
      <el-aside class="aside" :width="isCollapse ? '64px' : '200px'">
        <Navbar-index
          :defaultOpen="defaultOpen"
          v-if="$store.state.columnlist.length > 0"
          :isCollapse="isCollapse"
          :manageColumnList="$store.state.columnlist[topActive]"
        ></Navbar-index>
      </el-aside>

      <el-container class="center">
        <el-main class="main">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
      </el-container>
    </el-container>

    <!-- <el-container class="box1">
      
      <el-container class="center">
        <el-main class="main">
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </el-main>
        <el-footer class="footer">万旅网Copyright 2016 Corporation,All Rights.</el-footer>
      </el-container>
    </el-container> -->
    <div class="orderMsg" v-if="isNewOder">
      <div class="close" @click="onClose">
        <p></p>
        <strong>订单消息</strong>
        <p>x</p>
      </div>
      <div class="item" v-for="item in orderMsg" :key="item.orderId">
        <p>{{item.orderType==1?'待确认':item.orderType==2?'待退款':'订制'}}</p>
        <strong class="prodName">{{ item.prodName }}</strong>
        <span @click="onGOtoOrderDetail(item)">立即处理</span>
      </div>
    </div>
  </el-container>
</template>
<script>
import NavbarIndex from "../../components/navbar";
import { loginOut, loginMessage } from "../../api/login";
import { findColumnListByUser } from "../../api/column";
import { selectNoReview, selectNoSure } from "../../api/order";
export default {
  name: "Index",
  components: {
    NavbarIndex,
  },
  data: () => {
    return {
      isCollapse: false,
      aside: "index",
      topActive: Number(window.sessionStorage.getItem("columnA")) || 0,
      columnList: [],
      title: "",
      timer: null,
      isNewOder: false,
      newOrderList: {},
      orderMsg: [],
      orderMsgCount: null,
      defaultOpen: [],
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getOrderMsg();
    let that = this;
    this.timer = setInterval(() => {
      this.getOrderMsg();
    }, 180000);

    this.loginMessage();
    if (!window.sessionStorage.getItem("POWERLIST")) {
      this.$router.push("/login");
      setTimeout(() => {
        this.$message({
          message: "请先登录!",
          type: "error",
          showClose: true,
        });
      }, 1000);
    }
  },
  methods: {
    onGOtoOrderDetail(item) {
      const {coding,orderId} = item
      if(coding=='0000'){
       return  this.$router.push("/customInfoDetails?id=" + orderId);
      }
      try {
        this.onset(9);
      } catch (e) {
        console.log(e, "e");
      }
      this.$router.push("/refundOrderdetail/" + coding);
    },
    async getOrderMsg() {
      const { data } = await selectNoSure();
      this.orderMsg = data.data;
      if (data.count > 0) {
        this.isNewOder = true;
      } else {
        this.isNewOder = false;
      }
      this.orderMsgCount = data.count;

      if (this.orderMsgCount > 0) {
        let that = this;
        if (window.Notification && Notification.permission !== "denied") {
          Notification.requestPermission(function (status) {
            var notification = new Notification("万旅网订单消息提醒", {
              dir: "rtl",
              body: ` 
                新增${that.orderMsgCount}条订单消息    
              `,
              icon:
                "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.51yuansu.com%2Fpic3%2Fcover%2F01%2F94%2F05%2F598331872dce9_610.jpg&refer=http%3A%2F%2Fpic.51yuansu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1616902620&t=ac2159b0e1b715477c553075430c14ca",
            });
            notification.onclick = function () {
              that.onset(9);
              that.$router.push("/refundOrderdetail/" + that.orderMsg[0].coding);
            };
          });
        }
      }
    },
    onGOtoOrder(type) {
      console.log("this");
      try {
        this.onset(9);
      } catch (e) {
        console.log(e, "e");
      }
      if (Number(type) !== 7) {
        this.$router.push("/orderList/" + type);
      } else {
        this.$router.push("/orderRefund");
      }
    },
    onClose() {
      this.isNewOder = false;
    },
    async getNewOrder() {
      const { data } = await selectNoReview();
      this.newOrderList = data.data[0];
      for (const key in this.newOrderList) {
        if (this.newOrderList[key] > 0) {
          this.isNewOder = true;
        }
      }
    },
    async loginMessage() {
      const { data } = await loginMessage();
      this.title = data.msg;
    },
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    onSubmenuClick() {
      if (this.isCollapse) {
        this.isCollapse = false;
      }
    },
    onIndex() {
      this.aside = "index";
      this.$router.push("/home");
    },
    onset(id) {
      this.defaultOpen = [];
      console.log(id, "asfdfasdf");
      window.sessionStorage.setItem("columnA", id);
      this.topActive = id;
      this.aside = "set";
      console.log(this.$store.state.columnlist, "list");
      let list = this.$store.state.columnlist;
      let url = list[id].manageColumnList[0].manageColumnList[0].columnRouteUrl;
      this.$router.push(`${url}`);
      this.activePath = url;
      console.log(this.activePath);
      this.defaultOpen.push(list[id].manageColumnList[0].columnId + "");
      console.log(this.defaultOpen);
      window.sessionStorage.setItem("activePath", url);

      // this.saveNavState(url);
      // this.open();
    },
    async loginOut() {
      // try {
      const { data } = await loginOut();
      // if (data.code === 0) {
      // this.$router.push("/");
      this.$router.push("/login");
      window.sessionStorage.clear();
      //   } else {
      //     this.$message({
      //       message: data.msg,
      //       type: "warning",
      //     });
      //   }
      // } catch (error) {
      //   this.$message({
      //     message: "请稍后重试",
      //     type: "warning",
      //   });
      // }
    },
    onUpdateUserPassword() {
      this.$router.push("/updateuseruassword");
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  color: aqua;
}
.main-box {
  height: 100vh;

  // height: 500px;
}
.header {
  display: flex;
  // justify-content: space-between;
  height: 60px !important;
  padding: 0;
  position: fixed;
  left: 0;

  top: 0;
  right: 0;
  z-index: 99;
  // padding-bottom: 60px;
  justify-content: space-between;
  background-color: #545c64;
  .left {
    display: flex;
    // justify-content: space-around;
    float: left;
    // width: 200px !important;
    height: 100%;
    .wanlvzuofanye {
      width: 50px;
      font-size: 28px !important;
      text-align: center;
      background-color: rgb(84, 92, 100);
      cursor: pointer;
      transition: all 0.3s linear;
    }
    .logo {
      background: url("../../assets/images/logo_03.jpg") no-repeat center center;
      background-size: 120px 50px;
      margin-left: 20px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      &:hover {
        cursor: pointer;
      }
    }
    .is-close {
      transform: rotate(180deg);
    }
  }

  div {
    float: left;
    width: 75px;
    line-height: 60px;
    color: aqua;
  }

  ul {
    flex: 1;
    color: #fff;
    display: flex;
    justify-self: start;
    align-items: center;
    li {
      line-height: 60px;
      margin-left: 40px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        cursor: pointer;
        color: aqua;
      }
    }
  }
  .userInfo {
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 500px;
    width: 160px;
    justify-self: flex-end;
    color: #fff;
    .title {
      width: 300px;
      height: 60px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-dropdown {
      width: 120px;
      height: 60px;
      margin-right: 20px;
      .el-dropdown-link {
        display: flex;
        justify-content: center;
        .el-avatar--circle {
          margin-top: 8px;
        }
        .message {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #fff;
          }
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.aside {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 60px;
  width: 200px;
  z-index: 999;
  transition: 0.3s all linear;
  padding-bottom: 30px;

  background-color: rgb(84, 92, 100);
  .toggle-button {
    // text-align: center;
    margin-left: 25px;
    color: #fff;
    &:hover {
      cursor: pointer;
    }
  }
  .el-menu {
    overflow: hidden;
    border: 1px;
  }
}

.center {
  height: calc(100vh - 60px);
  // overflow-x: scroll;
  box-sizing: border-box;
  // position: fixed;
  // left: 230px;
  // bottom: 0;
  // top: 70px;
  // right: 0;
  padding-top: 70px;
  padding-left: 230px;
  .footer {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    height: 60px !important;
    text-align: center;
    line-height: 60px;
    font-size: 15px;
    background-color: #fff;
  }
}
.orderMsg {
  position: fixed;
  bottom: 14px;
  right: 14px;
  width: 600px;
  max-height: 500px;
  min-height: 120px;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #ebeef5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s, bottom 0.3s;
  z-index: 999;

  .close {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 20px;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    margin: 18px auto;
    padding: 0 10px;
    .prodName {
      display: -webkit-inline-box;
      width: 390px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      text-align: justify;
    }
  }
  .num {
    color: #f05f5c;
    text-decoration: underline;
    cursor: unset;
  }
  span {
    margin-left: 10px;
    color: #f05f5c;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
